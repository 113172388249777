<template>
    <router-link :to="{ name: 'Collection', params: { id: data.id } }">
        <h4 class="name">{{ data.name }}</h4>
    </router-link>
</template>

<script>
export default {
    name: 'CollectionButton',
    props: {
        data: Object,
        index: Number
    },
    data() {},
}
</script>
