export default {
    usa: [
        { name: "Alabama", key: "AL" },
        { name: "Alaska", key: "AK" },
        { name: "Arizona", key: "AZ" },
        { name: "Arkansas", key: "AR" },
        { name: "California", key: "CA" },
        { name: "Colorado", key: "CO" },
        { name: "Connecticut", key: "CT" },
        { name: "Delaware", key: "DE" },
        { name: "District Of Columbia", key: "DC" },
        { name: "Florida", key: "FL" },
        { name: "Georgia", key: "GA" },
        { name: "Hawaii", key: "HI" },
        { name: "Idaho", key: "ID" },
        { name: "Illinois", key: "IL" },
        { name: "Indiana", key: "IN" },
        { name: "Iowa", key: "IA" },
        { name: "Kansas", key: "KS" },
        { name: "Kentucky", key: "KY" },
        { name: "Louisiana", key: "LA" },
        { name: "Maine", key: "ME" },
        { name: "Maryland", key: "MD" },
        { name: "Massachusetts", key: "MA" },
        { name: "Michigan", key: "MI" },
        { name: "Minnesota", key: "MN" },
        { name: "Mississippi", key: "MS" },
        { name: "Missouri", key: "MO" },
        { name: "Montana", key: "MT" },
        { name: "Nebraska", key: "NE" },
        { name: "Nevada", key: "NV" },
        { name: "New Hampshire", key: "NH" },
        { name: "New Jersey", key: "NJ" },
        { name: "New Mexico", key: "NM" },
        { name: "New York", key: "NY" },
        { name: "North Carolina", key: "NC" },
        { name: "North Dakota", key: "ND" },
        { name: "Ohio", key: "OH" },
        { name: "Oklahoma", key: "OK" },
        { name: "Oregon", key: "OR" },
        { name: "Pennsylvania", key: "PA" },
        { name: "Rhode Island", key: "RI" },
        { name: "South Carolina", key: "SC" },
        { name: "South Dakota", key: "SD" },
        { name: "Tennessee", key: "TN" },
        { name: "Texas", key: "TX" },
        { name: "Utah", key: "UT" },
        { name: "Vermont", key: "VT" },
        { name: "Virginia", key: "VA" },
        { name: "Washington", key: "WA" },
        { name: "West Virginia", key: "WV" },
        { name: "Wisconsin", key: "WI" },
        { name: "Wyoming", key: "WY" }
    ]
}