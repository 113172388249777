<template>
  <div class="intro">
    <div class="image-absolute"></div>
    <!-- <Hero title="Welcome" image="Welcome.webp"/> -->
    <div class="text-container wrapper" ref="textContainer">
      <div class="intro-text">
        <h1 class="title-hero">
          Welcome To Sephora's 2024 Holiday Press Hub
        </h1>
        <p class="p-large strong">
          We are thrilled to welcome you to Sephora’s 2023 Holiday Press Hub!
        </p>
        <p class="p-large">
          We hope this site will serve as your one-stop shop to explore Sephora’s most-anticipated launches, broken down
          into categories to take the guesswork out of gift guides. Whether you know exactly what you’re looking for, or
          you want to peruse every must-have beauty product of the season, we’ve got you covered.
        </p>
        <p class="p-large">
          Our offerings include limited-edition products from many of our beloved brand partners, specially curated
          Sephora Favorites Kits, and unbeatable value from our in-house brand Sephora Collection.
        </p>
        <p class="p-large team">By the Sephora team</p>
        <div class="button-actions">
          <Button to='/experience'>Enter experience</Button>
          <Button outlined to='/home'>Go straight shopping</Button>
        </div>
      </div>
    </div>
    <!-- <div class="bottom">
      <div class="bot">

      </div>
    </div> -->
  </div>
</template>
<script>
import Button from 'components/Button/Button';
import Hero from 'components/Hero/Hero';

export default {
  name: 'Intro',
  components: {
    Button,
    Hero
  },
  data() {
    return {
      type: 1,
      products: {},
      height: 0,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    }
  },
  watch: {
    windowWidth: function () {
      this.height = this.$refs.textContainer.clientHeight + 160;
    },
  },
  mounted: function () {
    let user = JSON.parse(localStorage.user);
    this.type = user.type;
    this.$nextTick(() => {
      this.height = this.$refs.textContainer.clientHeight + 160;
      window.addEventListener('resize', this.onResize);
    });
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth

    },
  }
}
</script>

