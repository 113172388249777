<template>
    <div class="cart-list">
        <div class="item" v-for="item in products" :key="item.productID">
            <div class="item-image">
                <LazyImage :src="getImgUrl(item)" :alt="item.title"/>
            </div>
            <div class="text">
                <p class="brand">{{ item.brand }}</p>
                <p class="title">{{ item.title }}</p>
            </div>
            <div class="delete-btn" @click="handleDelete(item.productID)"/>
        </div>
    </div>
</template>

<script>
import LazyImage from 'components/LazyImage/LazyImage';

export default {
    name: 'ProductList',
    components: {
        LazyImage
    },
    props: {
        products: Array
    },
    emits: ['delete'],
    methods: {
        handleDelete(id) {
            this.$emit('delete', id)
        },
        getImgUrl(product) {
            return `${ process.env.VUE_APP_BASE_URL }/getImage/${ product.productID }/${ product.heroImage }`
        }
    }
}
</script>