<template>
    <div :class="'separator' + (marginTop ? ' margin-top' : '')">
        <div class="line"/>
        <p>{{ text }}</p>
        <div class="line"/>
    </div>
</template>

<script>
export default {
    name: 'Separator',
    props: {
        text: String,
        marginTop: Boolean
    },
}
</script>
