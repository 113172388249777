<template>
  <div class="expert page">
    <Hero :title="name" :description="description" alignLeft backButton image="blue-bg2.webp"
          :expert="'experts/' + image" :instagramUrl="instagram" :id="handle"/>
    <List :quotes="quotes"/>
  </div>
</template>
<script>
import Hero from 'components/Hero/Hero';
import List from './components/List';
import experts from 'assets/data/experts.json';
import products from 'assets/data/products.json';

export default {
  name: 'Expert',
  components: {
    Hero,
    List
  },
  data() {
    return {
      name: '',
      description: '',
      image: '',
      instagram: '',
      quotes: [],
      handle: '',
      currentRoute: this.$route.path,
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        // A URL mudou, você pode executar a lógica que deseja aqui
        // Por exemplo, você pode recarregar a página usando window.location.reload()
        window.location.reload();
      }
    },
  },
  created() {
    const pathArr = this.$route.path.split('/');
    const data = experts[pathArr[pathArr.length - 1]];

    if (data) {
      this.name = data.name;
      this.description = data.description;
      this.image = data.image;
      this.instagram = data.instagram;
      this.handle = data.handle;
      this.quotes = data.quotes.map(el => ({
        ...el,
        product: products[el.productID] || null
      })).filter(el => el.product);
    }
  }
}
</script>
