<template>
    <header>
        <div class="header-navigation" :class="menuActive && 'active'">
            <div class="fixed">
                <div class="link" :class="menuActive && 'active'" v-on:click="updateMenu()" v-if="isLogged">
                    <div class="icon hamburguer" v-if="!menuActive"></div>
                    <div class="icon close-menu" v-if="menuActive"></div>
                    <span>Menu</span>
                </div>
                <div class="main">
                    <!-- <router-link class="logo" :class="loginPage && 'white'" :to="loginPage?'/':'/home'"/> -->
                    <router-link class="logo" :to="loginPage ? '/' : '/home'"/>
                </div>
                <router-link :class="cartPage && 'active'" class="link" v-if="isLogged" to="/cart">
                    <div class="icon bag"></div>
                    <span>Holiday Wishlist</span>
                    <span v-if="cartSize > 0" class="badge">{{ cartSize }}</span>
                </router-link>
            </div>
        </div>
        <Menu :visible="menuActive"></Menu>
    </header>
</template>

<script>
import Menu from './Menu';

export default {
    name: 'Header',
    components: {
        Menu
    },
    data() {
        return {
            menuActive: false,
            isLogged: false,
            cartPage: false,
            loginPage: false,
            cartSize: 99
        };
    },
    mounted() {
        this.updateCartSize();

        window.addEventListener('cart-changed', () => {
            this.updateCartSize();
        });

        this.$watch(
            () => this.$route.params,
            () => {
                this.menuActive = false;
                this.loginPage = Boolean(this.$route.name === 'Login');
                this.isLogged = Boolean(!this.loginPage);
                // this.isLogged = Boolean(this.$route.name !== 'Intro' && !this.loginPage);
                this.cartPage = Boolean(this.$route.name === 'Holiday Wishlist');

                if (this.loginPage) {
                    document.body.className = 'fullwidth-background';
                } else {
                    document.body.className = '';
                }
            }
        )
    },
    methods: {
        updateMenu() {
            this.menuActive = !this.menuActive;
        },
        updateCartSize() {
            this.cartSize = localStorage.cart ? JSON.parse(localStorage.cart).length : 0;
        }
    }
}
</script>
