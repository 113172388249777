<template>
    <div class="image-gallery" >
      <div class="main" :class="{'loaded': images, 'expert': expert}">

      <img v-if="images" :src="currentImage" alt="Product details"/>
        </div>
        <div class="list">
            <div class="preview-element" @click="() => handleChangeImg(index)" v-for="(image, index) in (images || [1, 2, 3, 4, 5, 6])"
                :key="index" :class="[current === index && 'selected', images && 'loaded']"
            >
                <LazyImage v-if="images" :src="image" alt="Product details"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import LazyImage from 'components/LazyImage/LazyImage';

export default {
    name: 'ImageGallery',
    props: {
        productID: String,
        heroImage: String,
        expert: Boolean
    },
    components: {
        LazyImage,
    },
    data() {
        return {
            current: 0,
            images: null
        }
    },
    computed: {
        currentImage() {
            return this.images ? this.images[this.current] : '';
        }
    },
    methods: {
        handleChangeImg(index) {
            this.current = index;
        }
    },
    created() {

      console.log("expert", this.expert)
        const headers = {
            headers: { 'authorization': "Bearer " + localStorage.token }
        }
        const body = {
            productID: this.productID,
            heroImage: this.heroImage
        }

        axios.post(process.env.VUE_APP_BASE_URL + "/getImages", body, headers)
        .then(resp => {
            if (resp.status !== 200) {
                console.log(resp);
                return;
            }

            this.images = resp.data.list;
        })
        .catch(err => {
            console.log(err);
        });
    }
}
</script>