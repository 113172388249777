<template>
    <footer>
        <!-- <div v-if="isLogged" class="footer-container">
            <div class="logo"/>
            <div class="links">
                <router-link to="/home"><p>Home</p></router-link>
                <router-link to="/intro"><p>Welcome page</p></router-link>
                <router-link to="/faq"><p>FAQ</p></router-link>
                <router-link to="/how-to-shop"><p>How to shop</p></router-link>
              <router-link to="/re-purposed"><p>Beauty (Re)Purposed</p></router-link>
            </div>
        </div> -->
        <div class="copyright">
            <p>© 2024 Sephora USA, Inc. All rights reserved.</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            isLogged: false,
        };
    },
    mounted() {
        this.$watch(
            () => this.$route.params,
            () => {
                this.loginPage = (this.$route.name === 'Login')? true: false;
                this.isLogged = (this.$route.name === 'Intro' || this.loginPage)? false: true;
            }
        )
    },
}
</script>