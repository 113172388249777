<template>
    <a class="instagram" :href="url" target="_blank" v-on:click.stop="doThis">
        <div class="instagram-link">
            <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.01011 -0.000976562C9.06452 0.0169965 10.1203 0.0266743 11.1733 0.0550165C12.0921 0.0799023 12.9878 0.229563 13.7962 0.698591C14.9212 1.35115 15.593 2.3414 15.812 3.60919C15.9289 4.28595 15.9722 4.98171 15.9829 5.66988C16.0071 7.22524 16.0022 8.7806 15.9853 10.336C15.9766 11.123 15.967 11.9148 15.7563 12.6839C15.2571 14.5016 14.0484 15.5457 12.2105 15.8471C11.5532 15.9546 10.88 15.9767 10.2134 15.9854C8.74349 16.0051 7.27292 16.0023 5.80374 15.9871C5.02711 15.9788 4.24703 15.9695 3.48286 15.7953C1.65494 15.3805 0.407151 14.0585 0.14943 12.2076C0.0629422 11.5518 0.0173062 10.8913 0.0127854 10.2299C-0.00658701 8.74362 -0.0045114 7.25738 0.0127854 5.77115C0.0207419 5.0066 0.0335415 4.23998 0.204779 3.48684C0.614712 1.67467 1.94137 0.417934 3.7814 0.163546C4.46947 0.0681507 5.16964 0.0463756 5.86566 0.0252918C6.57967 0.0024798 7.29506 0.0201072 8.01046 0.0201072C8.00977 0.0125032 8.00977 0.00559052 8.01011 -0.000976562ZM1.44772 7.98667H1.46364C1.46364 8.26318 1.45637 8.53969 1.46364 8.8162C1.49546 9.83998 1.49062 10.8669 1.57849 11.8858C1.69092 13.1861 2.52254 14.0764 3.79247 14.3668C4.50579 14.5299 5.23467 14.5209 5.95941 14.5282C7.32009 14.542 8.68076 14.542 10.0414 14.5282C10.651 14.5213 11.2629 14.4891 11.869 14.4245C13.1784 14.288 14.0726 13.4806 14.3653 12.2024C14.5289 11.4897 14.5189 10.7611 14.5265 10.0373C14.5408 8.67783 14.5408 7.31833 14.5265 5.95883C14.5196 5.34947 14.4871 4.73839 14.4227 4.13249C14.2861 2.83324 13.4769 1.93804 12.1945 1.64425C11.4871 1.48215 10.7634 1.49183 10.0449 1.48457C8.6788 1.47005 7.31201 1.46556 5.94696 1.4863C5.26892 1.49632 4.58639 1.53157 3.91597 1.62697C3.07154 1.74691 2.39801 2.17999 1.9604 2.93313C1.61446 3.52831 1.52383 4.18986 1.5048 4.86074C1.47505 5.90249 1.46571 6.94631 1.44772 7.98667Z" fill="#CCCCCC"/>
                <path d="M12.1177 8.00612C12.1177 10.2714 10.2576 12.1261 7.99 12.122C5.7307 12.1182 3.87925 10.2645 3.87891 8.00647C3.87891 5.74117 5.73865 3.88683 8.00695 3.89063C10.2659 3.89409 12.118 5.74842 12.1177 8.00612ZM10.6544 7.99921C10.6478 6.54201 9.44984 5.34956 7.99519 5.35198C6.54053 5.3544 5.3429 6.54926 5.34117 8.00508C5.33944 9.4609 6.55195 10.671 8.00937 10.661C9.4668 10.6509 10.6613 9.45088 10.653 7.99921H10.6544Z" fill="#CCCCCC"/>
                <path d="M13.1957 3.72051C13.1951 3.97339 13.0945 4.21579 12.9159 4.39497C12.7373 4.57414 12.4951 4.67559 12.242 4.67723C12.1148 4.67964 11.9884 4.6567 11.8701 4.60973C11.7519 4.56276 11.6442 4.49271 11.5534 4.40368C11.4626 4.31465 11.3905 4.20842 11.3412 4.09121C11.292 3.97399 11.2666 3.84815 11.2666 3.72102C11.2666 3.5939 11.292 3.46805 11.3412 3.35084C11.3905 3.23363 11.4626 3.1274 11.5534 3.03837C11.6442 2.94934 11.7519 2.87929 11.8701 2.83232C11.9884 2.78535 12.1148 2.7624 12.242 2.76482C12.4951 2.76574 12.7376 2.8668 12.9164 3.04591C13.0951 3.22502 13.1956 3.46758 13.1957 3.72051Z" fill="#CCCCCC"/>
            </svg>
        </div>
        <p>{{id}}</p>
    </a>


</template>

<script>
export default {
    name: 'InstagramLink',
    props: {
        url: String,
        id: String
    },
    computed: {
        username() {
            return this.url.split('instagram.com/').slice(-1)[0].replace(/\//g, '');
        }
    }
}
</script>
