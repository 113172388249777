<template>
  <div class="hero" :class="[pdp && 'pdp']">
    <div class="content">

      <h1>{{ title }}</h1>
      <p class="p-large">{{ description }}</p>
      <InstagramLink v-if="instagramUrl" :url="instagramUrl" :id="id" />

      <div v-if="expert" class="expert">
        <img :src="require(`assets/images/${expert}`)" :alt="title">
      </div>

    </div>

    <div v-if="image" class="image">
      <img :src="require(`assets/images/${image}`)" :alt="title" />
    </div>

    <div v-if="backButton" @click="handleBackClick" class="back-button"></div>
  </div>
</template>

<script>
import InstagramLink from '../InstagramLink/InstagramLink';

export default {
  name: "Hero",
  components: {
    InstagramLink,
  },
  props: {
    title: String,
    description: String,
    image: String,
    instagramUrl: String,
    pdp: Boolean,
    backButton: Boolean,
    expert: String,
    id: String,
  },
  methods: {
    handleBackClick() {
      this.$router.back();
    }
  }
};
</script>