<template>
  <div class="re-purposed page">
    <Hero backButton title="Beauty (Re)Purposed" image="BeautyRePurposed.webp"
      description="Give your finished beauty products a second life at a Sephora near you." />
    <div class="re-purposed__content wrapper">
      <div class="first-section">
        <p class="p-large">
          The beauty and wellness industries produce <strong>120 billion packages</strong> every year, most of which
          <strong>aren't accepted</strong> in
          curbside recycling programs.
        </p>
        <p class="p-large">
          And that's 120 billion reasons why we created our <strong>Beauty (Re)Purposed program</strong> with Pact
          Collective.
          Pop into your local Sephora to drop off those empties (instead of throwing them away) and join us in doing
          better for the
          planet.
        </p>
        <Button>
          <a target="_blank" href="https://www.sephora.com/beauty/pact-collective-recycling-program">Get started</a>
        </Button>
      </div>
      <Separator text="How it works" />
      <div class="steps">
        <div class="step">
          <div class="bg-step">
            <!-- <img src="https://sephora-media-hub.s3.us-east-2.amazonaws.com/assets/images/clean.webp" /> -->
            <img src="~@/assets/images/gift-stocking-stuffers.png" alt="Gift Box">
          </div>
          <h3>Clean it out</h3>
          <p class="p-large">At home, make sure your packaging is clean, unbagged, and free of liquid or product.</p>
        </div>
        <div class="step">
          <div class="bg-step">
            <img src="~@/assets/images/bag-gift.png" alt="Bag Gift">
          </div>
          <h3>Drop it off</h3>
          <p class="p-large">Visit any Sephora store and drop your empties in dedicated collections bin.</p>
        </div>

        <div class="step">
          <div class="bg-step">
            <img src="~@/assets/images/float-heart.png" alt="Float Heart">
          </div>
          <h3>Close the loop</h3>
          <p class="p-large">You did it! Instead of being thrown away, your beauty empties may be repurposed into
            carpets, pallets, asphalt, new packaging and more!*</p>
        </div>
      </div>

      <p class="p-body">*Depending on volumes/contamination of empties collected for each eligible material.</p>

    </div>
  </div>
</template>

<script>
import Hero from 'components/Hero/Hero';
import Button from 'components/Button/Button';
import Separator from 'components/Separator/Separator'

export default {
  name: 'RePurposed',
  components: {
    Hero,
    Button,
    Separator
  },
  data() {
    return {
      tabs: ['Same-day delivery', 'Pick up in store', 'Pick up curbside', 'Free shipping'],
      tab: 0
    }
  },
  methods: {
    handleChangeTab(val) {
      this.tab = val;
    }
  }
}
</script>