<template>
    <div class="product-list">
        <div v-if="filteredProducts" class="product-list__content" :class="loadMoreVisible && 'load-more-visible'">
            <div class="list">
                <ProductCard compact v-for="product in filteredProducts" :key="product.productID" :product="product"/>
            </div>
            <div v-if="loadMoreVisible" class="load-more-btn">
                <Button outlined @click="handleLoadMore">Load more</Button>
            </div>
        </div>
        <Spinner v-else/>
    </div>
</template>

<script>
import Spinner from '../Spinner/Spinner';
import Button from '../Button/Button';
import ProductCard from '../ProductCard/ProductCard';
import products from 'assets/data/products';

export default {
    name: 'ProductList',
    components: {
        Spinner,
        Button,
        ProductCard
    },
    props: {
        products: Array,
        compact: Boolean,
        initialNumToShow: {
            type: Number,
            default: 12
        },
    },
    data() {
        return {
            validProducts: null,
            filteredProducts: null,
            loadedSlices: 1,
        }
    },
    computed: {
        loadMoreVisible() {
            return this.filteredProducts.length < this.validProducts.length;
        }
    },
    created() {
        if (this.products) {
            this.validProducts = this.products.map(el => products[el] || null).filter(el => el).sort((a, b) => a.brand.localeCompare(b.brand));
            this.filteredProducts = this.validProducts.slice(0, this.initialNumToShow);
        }
    },
    methods: {
        handleLoadMore() {
            this.loadedSlices++;
            this.filteredProducts = this.validProducts.slice(0, this.initialNumToShow * this.loadedSlices);
        },
    }
}
</script>
