<template>
  <div class="product-detail page">
    <div @click="handleBackClick" class="back-button"/>
    <div class="product-detail__content">
      <ImageGallery :productID="product.productID" :heroImage="product.photoHeroID" :expert="product.expertPick"/>
      <div class="infos">
        <!-- <p class="brand">{{ product.brand }}</p> -->
        <h1 class="small">{{ product.title }}</h1>
        <p class="price">{{ formatPrice }}</p>
        <p class="description">{{ product.description }}</p>
        <p class="embargo" v-if="product.embargo">Under Embargo Until Launch</p>
        <p class="link" v-else @click="handleLinkClick">Available now on Sephora.com</p>
        <div class="buttons">
          <Button class="whitebag" leftIcon='whitebag' @click="handleRequestSample" :disabled="(!product.sampleRequest)">
            {{ requested ? 'Added to wishlist' : 'Request a sample' }}
          </Button>
          <Button class="download" leftIcon='download' outlined @click="handleDownloadImages">
            Download Images
          </Button>
        </div>
        <p class="upon-request" v-if="product.availableUponRequest">Sample available upon request at <a
          href="mailto:msheridan@devriesglobal.com">msheridan@devriesglobal.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import products from 'assets/data/products.json';
import Button from 'components/Button/Button';
import ImageGallery from './components/ImageGallery';
import axios from 'axios';
import {useGtag} from "vue-gtag-next";

export default {
  name: 'ProductDetail',
  components: {
    Button,
    ImageGallery
  },
  data() {
    return {
      product: null,
      requested: false
    }
  },
  computed: {
    formatPrice() {
      let str = '';
      if (this.validPrice(this.product.price)) str += `$${this.product.price}`;
      if (this.validPrice(this.product.value) && this.product.value.toUpperCase() !== "NO") str += ` (Value $${this.product.value})`;
      return str;
    }
  },
  methods: {
    validPrice(value) {
      return !(!value || String(value).toLowerCase().includes('n/a'))
    },
    handleRequestSample() {
      const updatedCart = [...(localStorage.cart ? JSON.parse(localStorage.cart) : []), this.product.productID];
      localStorage.cart = JSON.stringify(updatedCart);
      this.requested = true;
      window.dispatchEvent(new CustomEvent('cart-changed'));
      this.trackRequest(this.product.productID);
    },
    handleDownloadImages() {
      axios.get(process.env.VUE_APP_BASE_URL + '/download/images/' + this.product.productID, {responseType: 'blob'})
        .then(response => {
          const blob = new Blob([response.data], {type: 'application/zip'});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = this.product.productID;
          link.click();
          URL.revokeObjectURL(link.href);
          this.trackDownload(this.product.productID);
        })
        .catch(err => {
          console.log(err);
        })
    },
    trackDownload(id) {
      let user = JSON.parse(localStorage.user);
      const { set, event, config } = useGtag()
      config({
        'user_id': user.id + "",
        'dimension1': 'Visitor',
      });
      set({
        'dimension1': 'Visitor',
      });
      event('download_file', {
        'user_id': user.id + "",
        'event_category' : 'download_file',
        'event_label' : 'Downloading product - ' + id
      })
    },
    trackRequest(id) {
      let user = JSON.parse(localStorage.user);

      const { set, event, config } = useGtag()
      config({
        'user_id': user.id + "",
      });
      set({
        'dimension1': 'Visitor',
      });
      event('request_product', {
        'user_id': user.id + "",
        'event_category' : 'request product',
        'event_label' : 'Requesting product - ' + id
      })
    },
    handleBackClick() {
      this.$router.back();
    },
    handleLinkClick() {
      window.open(this.product.link || 'https://www.sephora.com/', '_blank');
    }
  },
  created() {
    const pathArr = this.$route.path.split('/');
    this.product = products[pathArr[pathArr.length - 1]];

    if (localStorage.cart && JSON.parse(localStorage.cart).includes(this.product.productID)) {
      this.requested = true;
    }
  },
  mounted() {
    const slider = document.querySelector('.image-gallery .list');
    let isDown = false;
    let startX;
    let scrollLeft;
    console.log("productds", this.product.sampleRequest)

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2;
      slider.scrollLeft = scrollLeft - walk;
    });
  }
}
</script>