<template>
    <div class="tab-bar">
        <div class="tab-bar__items">
            <div v-for="(tab, index) in tabs" :key="tab">
                <p :class="index === selected && 'selected'" @click="() => handleChange(index)">{{ tab }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabBar',
    props: {
        tabs: Array,
        selected: Number
    },
    emits: ['change'],
    methods: {
        handleChange(tab) {
            this.$emit('change', tab)
        }
    }
}
</script>