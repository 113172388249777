<template>
  <div class="home page">
    <div class="hero-banner">
      <div class="section-hero">
        <div class="banner">
          <h1 class="white">
            Holiday Press Hub
          </h1>
          <p class="p-large white">
            [Welcome copy to explain what reporters will see.]
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Sed vel tincidunt mi, eget bibendum risus. Vestibulum lectus nunc, venenatis vitae.
          </p>
          <p class="p-large white">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
            ex ea commodo consequat.
          </p>
          <div class="collections">
            <CollectionButton v-for="(item, index) in collections" :index="index" :key="item.id" :data="item" />
          </div>
        </div>

        <div class="content-gift">
          <div class="gift-image-animation"></div>
          <div class="shadow-image"></div>
        </div>
      </div>
    </div>

    <div class="categories">
      <div class="title-description">
        <div class="line-title">
          <span class="line"></span>
          <h4>You can't go wrong with</h4>
          <span class="line"></span>
        </div>
        <p class="p-large">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Sed vel tincidunt mi, eget bibendum risus. Vestibulum
          lectus nunc, venenatis vitae consequat quis, mattis vitae quam.
        </p>
      </div>

      <div class="swiper-container">
        <ul class="swiper-wrapper">
          <li v-for="item in filteredCategories" :key="item.id" class="swiper-item swiper-slide">
            <router-link :to="{ name: 'Category', params: { id: item.id } }" :class="['category-card', item.background.split('.')[0]]">
              <div class="image-container">
                <div class="category-image"></div>
                <img src="~@/assets/images/shadow-category-card.svg" alt="Shadow" class="shadow-image" />
              </div>
              <h6 class="category-name">{{ item.name }}</h6>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="overthink-container">
        <div class="icon"></div>
        <Button outlined leftIcon="overthink">Don't overthink it</Button>
      </div>
    </div>

    <div class="experts">
      <div class="title-description">
        <div class="line-title">
          <span class="line"></span>
          <h4>Expert picks</h4>
          <span class="line"></span>
        </div>
        <p class="p-large">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Sed vel tincidunt mi, eget bibendum risus. Vestibulum
          lectus nunc, venenatis vitae consequat quis, mattis vitae quam.
        </p>
      </div>

      <div class="list" ref="expert">
        <ExpertCard v-for="item in experts" :key="item.id" :data="item" />
      </div>
    </div>
  </div>
</template>

<script>
import Hero from 'components/Hero/Hero';
// import CollectionCard from './components/CollectionCard';
import ExpertCard from './components/ExpertCard';
// import CategoryCard from './components/CategoryCard';
import CollectionButton from './components/CollectionButton.vue';
import Button from 'components/Button/Button';

import assets from 'assets/data';

export default {
  name: 'Home',
  components: {
    Hero,
    ExpertCard,
    CollectionButton,
    Button
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  data() {
    return {
      collections: Object.values(assets.collections),
      experts: Object.values(assets.experts),
      categories: Object.values(assets.categories),
      intervals: [],
      windowWidth: window.innerWidth,
      progress: 0,
      slides: 6,
    };
  },
  computed: {
    filteredCategories() {
      return this.categories.slice(2);
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);

    this.windowWidth = window.innerWidth

    this.initSwiper();
  },
  methods: {
    initSwiper() {
      /* eslint-disable */
      this.swiper = new Swiper(".swiper-container", {
        slidesPerView: "auto",
        freeMode: true,
        slideToClickedSlide: true,
        spaceBetween: 24,
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        //   draggable: true,
        //   dragSize: 50
        // },
        mousewheel: true
      });
    },
    handleResize() {
      this.windowWidth = window.innerWidth

      if (window.innerWidth < 1400) {
        this.slides = 4
      } else {
        this.slides = 7;
      }
    },
    handleArrowScroll(dir) {
      const list = this.$refs.expert;
      const compStyles = window.getComputedStyle(list, null);
      const gap = Number(compStyles.getPropertyValue('gap').replace('px', ''));
      const cardSize = list.firstElementChild.clientWidth + gap;
      const step = 5;
      let target = dir === 'right' ? list.scrollLeft + cardSize : list.scrollLeft - cardSize;
      let scrollAmount = list.scrollLeft;

      if (target < 0) target = 0;

      if (this.intervals.length > 0) return;

      const slideTimer = setInterval(function () {
        if (dir === 'left') {
          list.scrollLeft -= step;
          scrollAmount -= step;
        } else {
          list.scrollLeft += step;
          scrollAmount += step;
        }

        if ((dir === 'left' && scrollAmount <= (target + 5)) || (dir === 'right' && scrollAmount >= (target - 5))) {
          list.scrollLeft = target;
          window.clearInterval(slideTimer);
          this.intervals.filter(el => el !== slideTimer);
        }
      }, 5);
    },
  }
}
</script>
