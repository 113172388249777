<template>
    <div class="success-dialog" @click="handleClose">
        <div class="success-dialog__content">
            <div class="close-btn" @click="handleClose"/>
            <h3>We've received your order!</h3>
            <p>Happy holidays!</p>
            <Button to='/home'>Keep browsing</Button>
        </div>
    </div>
</template>

<script>
import Button from 'components/Button/Button';

export default {
    name: 'SuccessDialog',
    components: {
        Button,
    },
    emits: ['close'],
    methods: {
        handleClose() {
            this.$emit('close')
        }
    }
}
</script>