<template>
  <div class="experience">
    <div class="image-absolute-left"></div>
    <div class="image-absolute-top-right"></div>
    <div class="image-absolute-right"></div>
    <div class="container">
      <h1 class="title">
        Shopping for your favorite person?
      </h1>

      <section class="form-container">
        <h3 class="selected">Do they like gifts?</h3>
        <div v-if="step === 1" class="buttons-yes-no">
          <button class="choice" @click="handleAnswer('yes')" :class="{ active: step === 2 }">Yes</button>
          <button class="choice" @click="handleAnswer('no')">No</button>
        </div>

        <div class="form-container" v-if="step === 2 && !activeQuestion">
          <div class="buttons-yes-no selected">
            <button class="choice selected" @click="handleAnswer('yes')">Yes</button>
          </div>
          <div v-for="question in questions" :key="question.id">
            <h3 @click="showQuestion(question.id)">{{ question.mainQuestion }}</h3>
          </div>
        </div>

        <div v-if="activeQuestion">
          <div class="div-selected selected">
            <button class="choice" @click="handleAnswer('yes')">Yes</button>
            <h3>{{ selectedQuestion.affirmativeQuestion }}</h3>
          </div>
          <h3 class="selected">{{ selectedQuestion.subTitle }}</h3>
          <div class="buttons-yes-no">
            <button class="choice" @click="selectedQuestion.navigate">Yes</button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Button from 'components/Button/Button';

export default {
  name: 'Experience',
  components: {
    Button,
  },
  data() {
    return {
      type: 1,
      products: {},
      height: 0,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      step: 1,
      activeQuestion: null,
      questions: [
        {
          id: 'q1',
          mainQuestion: 'Do they have lips?',
          affirmativeQuestion: 'They have lips',
          subTitle: 'Ready to explore the best in holiday makeup?',
          navigate: () => this.navigateTo('HolidayMakeup'),
        },
        {
          id: 'q2',
          mainQuestion: 'Do they have a nose?',
          affirmativeQuestion: 'They have a nose',
          subTitle: 'Ready to explore the best in holiday fragrance?',
          navigate: () => this.navigateTo('FragrancePage'),
        },
        {
          id: 'q3',
          mainQuestion: 'Do they have hair?',
          affirmativeQuestion: 'They have hair',
          subTitle: 'Ready to explore the best in holiday haircare?',
          navigate: () => this.navigateTo('HairProductsPage'),
        },
        {
          id: 'q4',
          mainQuestion: 'Do they have skincare?',
          affirmativeQuestion: 'They have skincare',
          subTitle: 'Ready to explore the best in holiday skincare?',
          navigate: () => this.navigateTo('SkincarePage'),
        },
      ],
    }
  },
  watch: {
    windowWidth: function () {
      this.height = this.$refs.textContainer.clientHeight + 160;
    },
  },
  mounted: function () {
    let user = JSON.parse(localStorage.user);
    this.type = user.type;
    this.$nextTick(() => {
      this.height = this.$refs.textContainer.clientHeight + 160;
      window.addEventListener('resize', this.onResize);
    });
  },
  computed: {
    selectedQuestion() {
      return this.questions.find(q => q.id === this.activeQuestion);
    },
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth
    },
    handleAnswer(answer) {
      if (answer === 'no') {
        this.$router.push({ name: 'Home' });
      } else {
        this.step = 2;
      }
    },
    showQuestion(questionId) {
      this.activeQuestion = questionId;
    },
    navigateTo(pageName) {
      this.$router.push({ name: pageName });
    },
  }
}
</script>