<template>
    <figure v-lazyload class="lazy-image">
        <Spinner class="spinner"/>
        <img :data-url="src" class="image" :alt="alt"/>
    </figure>
</template>

<script>
import Spinner from "../Spinner/Spinner";

export default {
    name: 'LazyImage',
    components: {
        Spinner
    },
    props: {
        src: String,
        alt: String,
    },
    directives: {
        lazyload: {
            created: el => {
                function loadImage() {
                    const imageElement = Array.from(el.children).find(
                        el => el.nodeName === "IMG"
                    );
                    if (imageElement) {
                        imageElement.addEventListener("load", () => {
                            setTimeout(() => el.classList.add("loaded"), 100);
                        });
                        imageElement.addEventListener("error", () => {
                            imageElement.src = 'https://sephora-media-hub.s3.us-east-2.amazonaws.com/assets/images/red-ribbons.webp'
                        });
                        imageElement.src = imageElement.dataset.url;
                    }
                }

                function handleIntersect(entries, observer) {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            loadImage();
                            observer.unobserve(el);
                        }
                    });
                }

                function createObserver() {
                    const options = {
                        root: null,
                        threshold: "0"
                    };
                    const observer = new IntersectionObserver(handleIntersect, options);
                    observer.observe(el);
                }

                if (window["IntersectionObserver"]) {
                    createObserver();
                } else {
                    loadImage();
                }
            }
        },
    }
}
</script>