import collections from "./collections";
import experts from "./experts.json";
import categories from "./categories.json";
import states from './states';
import products from './products.json';

export default {
    collections,
    experts,
    categories,
    states,
    products
}