<template>
    <div class="delivery-options">
        <p>Delivery options</p>
        <div @click="handleChange('standard')">
            <input type="radio" id="standard" value="standard" v-model="value" />
            <span class="radio-checkmark"></span>
            <label for="standard">Standard delivery, your requests will get to you in 7 to 10 days.</label>
        </div>
        <div @click="handleChange('urgent')">
            <input type="radio" id="urgent" value="urgent" v-model="value" />
            <span class="radio-checkmark"></span>
            <label for="urgent">Urgent delivery, your requests will get to you as soon as possible.</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeliveryOptions',
    props: {
        modelValue: String,
    },
    emits: ['update:modelValue'],
    methods: {
        handleChange(value) {
            this.$emit('update:modelValue', value)
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>