<template>
    <button class="button" :class="[outlined && 'outlined', disabled === true && 'disabled', compact && 'compact']" :type="type" @click="handleClick" :disabled="disabled === true">
        <img v-if="leftIcon" :src="require(`assets/icons/${ leftIcon }.svg`)" class="left-icon"/> 
        <span>
            <slot/>
        </span>
    </button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        disabled: Boolean,
        leftIcon: String,
        outlined: Boolean,
        compact: Boolean,
        to: [String, Object],
        type: {
            type: String,
            default: 'button'
        }
    },
    emits: ['click'],
  mounted() {

      console.log("aq", this.disabled);
  },

  methods: {
        handleClick(e) {
            if (this.to) {
                this.$router.push(this.to);
            }

            this.$emit('click', e)
        }
    }
}
</script>