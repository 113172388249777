<template>
  <div class="category page">
    <!--    Ajustar imagem -->
    <Hero class="category" :title="name" :description="description" backButton image="Banner.png"/>
    <ProductList :products="products"/>
  </div>
</template>

<script>
import Hero from 'components/Hero/Hero';
import ProductList from 'components/ProductList/ProductList';
import categories from 'assets/data/categories.json';

export default {
  name: 'Category',
  components: {
    Hero,
    ProductList
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        window.location.reload();
      }
    },
  },
  data() {
    return {
      name: '',
      description: '',
      products: null,
      background: null,
      currentRoute: this.$route.path,
    }
  },
  created() {
    const pathArr = this.$route.path.split('/');
    const data = categories[pathArr[pathArr.length - 1]];

    if (data) {
      this.name = data.name;
      this.description = data.description;
      this.products = data.products;
      this.background = data.background;

      if (data.id === "clean-beauty-gifts") {
        this.image = `clean-seal.webp`;
      }
    }
  }
}
</script>
