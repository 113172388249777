<template>
  <div class="list">
    <div v-for="(quote, index) in quotes" :key="index" class="item">
      <div class="wrapper">
        <ProductCard compact :product="quote.product"/>
        <div class="quote">
          <p class="p-large">{{ quote.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from 'components/ProductCard/ProductCard';

export default {
  name: 'List',
  components: {
    ProductCard
  },
  props: {
    quotes: Array,
  },
}
</script>
