<template>
    <div class="tab-content pickup-in-store">
        <div class="text-area">
            <h2>FREE Standard Shipping on All Orders</h2>
            <p class="p-large">It's fast, reliable, and Beauty Insiders can apply it to every order. †Exclusions/terms
                apply.</p>
            <a href="https://www.sephora.com/beauty/shipping-information" target="_blank">
                <Button>See terms</Button>
            </a>
            <h3 class="spc-padding">Need it even faster?</h3>
            <p class="p-large">Expedited shipping is also available.</p>
            <a href="https://www.sephora.com/beauty/shipping-information" target="_blank">
                <Button>Learn more</Button>
            </a>
            <h3 class="spc-padding">Not a part of our free loyalty program?</h3>
            <a href="https://www.sephora.com/profile/BeautyInsider" target="_blank">
                <Button>Join now</Button>
            </a>
            <h4>Need more details? Check our main website below.</h4>
            <a href="https://sephora.com" target="_blank">
                <Button>Go to Sephora.com</Button>
            </a>
        </div>
        <div class="image-area">
            <img src="~@/assets/images/giftbox-freeshipping.png" alt="Blue Gold Giftbox" />
            <div class="shadow"></div>
        </div>
    </div>
</template>

<script>
import Button from 'components/Button/Button';

export default {
    name: 'FreeShippingTab',
    components: {
        Button
    }
}
</script>