<template>
    <div class="cart page">
        <Hero title="Holiday Wishlist" description="Short description. Lorem ipsum dolor sit amet, consectetur."
            backButton :ribbons="Boolean(products.length)" image="blue_background.png" expert="giftbox-2-branded.png" />

        <div class="cart__content">
            <div class="forms">
                <h2>Delivery information</h2>

                <Input label="Full Name" name="fullname" id="fullname" placeholder="Write your full name"
                    :helperText="errors.fullname" v-model="inputs.fullname" fullWidth marginBottom
                    :error="Boolean(errors.fullname)" :maxLength="250" />

                <Input label="Address 1" name="street" id="street" placeholder="Street, avenue, etc."
                    :helperText="errors.street" v-model="inputs.street" fullWidth marginBottom
                    :error="Boolean(errors.street)" :maxLength="250" />

                <Input label="Address 2" name="complement" id="complement" placeholder="Apartment, block, etc."
                    :helperText="errors.complement" v-model="inputs.complement" fullWidth marginBottom
                    :error="Boolean(errors.complement)" :maxLength="250" />

                <div class="inputs-row">
                    <Input label="Zip code" name="zipCode" id="zipCode" placeholder="_____" :helperText="errors.zipCode"
                        v-model="inputs.zipCode" fiveWidth marginBottom :error="Boolean(errors.zipCode)"
                        :maxLength="8" />

                    <Input label="Phone Number" name="number" id="number" placeholder="(000) 000-0000"
                        :helperText="errors.number" v-model="inputs.number" fiveWidth marginBottom
                        :error="Boolean(errors.number)" :maxLength="8" :mask="true" />
                </div>

                <Input label="City" name="city" id="city" placeholder="Write your city's name" :helperText="errors.city"
                    v-model="inputs.city" fullWidth marginBottom :error="Boolean(errors.city)" :maxLength="100" />

                <Input label="State" name="state" id="state" placeholder="Select your state" :helperText="errors.state"
                    v-model="inputs.state" select fullWidth marginBottom :error="Boolean(errors.state)">

                <option v-for="state in states" :key="state.key">{{ state.name }}</option>
                </Input>

                <Input label="Delivery instructions" name="instructions" id="instructions"
                    placeholder="Write any delivery instruction" v-model="inputs.instructions" fullWidth multiline
                    :rows="4" marginBottom :maxLength="2000" />

                <DeliveryOptions v-model="inputs.type" />

                <Button @click="handleSubmit" :disabled="loading">{{ loading ? 'Saving...' : 'Place request' }}</Button>
                <p v-if="errors.generic" class="generic-error">{{ errors.generic }}</p>
                <SuccessDialog v-if="successDialogVisible" @close="() => { successDialogVisible = false }" />
            </div>

            <div v-if="products.length" class="my-products" :class="{ 'no-products': !products.length }">
                <h2>My products</h2>
                <p class="num-products">{{ products.length }} product{{ products.length > 1 ? 's' : '' }} selected</p>
                <ProductList :products="products" @delete="handleDeleteProduct" />
            </div>

            <NoProducts class="my-products" v-else />
        </div>
    </div>
</template>

<script>
import Hero from 'components/Hero/Hero';
import Input from 'components/Input/Input';
import ProductList from './components/ProductList';
import NoProducts from './components/NoProducts';
import Button from 'components/Button/Button';
import DeliveryOptions from './components/DeliveryOptions';
import SuccessDialog from './components/SuccessDialog';
import states from 'assets/data/states';
import products from 'assets/data/products.json';
import axios from "axios";

export default {
    name: 'Cart',
    components: {
        Hero,
        Input,
        ProductList,
        NoProducts,
        Button,
        DeliveryOptions,
        SuccessDialog,
    },
    data() {
        return {
            products: [],
            states: states.usa,
            successDialogVisible: false,
            submitted: false,
            loading: false,
            inputs: {
                fullname: '',
                zipCode: '',
                street: '',
                number: '',
                complement: '',
                city: '',
                state: '',
                instructions: '',
                type: 'standard'
            },
            errors: {
                generic: '',
                fullname: '',
                zipCode: '',
                street: '',
                number: '',
                city: '',
                state: '',
            },
        }
    },
    created() {
        const localProducts = localStorage.cart ? JSON.parse(localStorage.cart) : null;

        if (localProducts) {
            this.products = localProducts.filter(el => products[el] && products[el].sampleRequest).map(el => products[el]);
        }
    },
    unmounted() {
        if (this.submitted) {
            localStorage.removeItem('cart');
        }
    },
    methods: {
        handleDeleteProduct(productID) {
            this.products = this.products.filter(el => el.productID !== productID);
            localStorage.cart = JSON.stringify(this.products.map(el => el.productID));
            window.dispatchEvent(new CustomEvent('cart-changed'));
        },
        handleSubmit() {
            if (!this.validateInputs()) {
                return;
            }

            const url = process.env.VUE_APP_BASE_URL + "/request";
            const data = {
                name: this.inputs.fullname.trim(),
                zipCode: this.inputs.zipCode.trim(),
                number: this.inputs.number.trim(),
                street: this.inputs.street.trim(),
                complement: this.inputs.complement.trim(),
                city: this.inputs.city.trim(),
                state: this.inputs.state.trim(),
                instructions: this.inputs.instructions.trim(),
                options: this.inputs.type.trim(),
                products: this.products.map(el => el.productID),
            };

            const config = {
                headers: {
                    'authorization': "Bearer " + localStorage.token
                }
            };

            this.loading = true;
            this.errors.generic = '';

            axios.post(url, data, config)
                .then(data => {
                    if (data.status !== 200) {
                        this.errors.generic = `Something went wrong: ${data.status}`
                        this.loading = false;
                        return;
                    }

                    this.onSubmitted();
                })
                .catch(err => {
                    this.errors.generic = `Something went wrong: ${err}`
                    this.loading = false;
                    console.log(err);
                });
        },
        onSubmitted() {
            window.scrollTo({ top: 0 })
            this.successDialogVisible = true;
            this.submitted = true;
            this.loading = false;
            this.inputs = {
                fullname: '',
                zipCode: '',
                street: '',
                number: '',
                complement: '',
                city: '',
                state: '',
                instructions: '',
                type: 'standard'
            }
        },
        validateInputs() {
            let valid = true;

            if (!this.inputs.fullname) {
                this.errors.fullname = 'Fill in the full name';
                valid = false;
            }
            if (!this.inputs.zipCode) {
                this.errors.zipCode = 'Fill in a valid zip code';
                valid = false;
            }
            if (!this.inputs.street) {
                this.errors.street = 'Fill in the street';
                valid = false;
            }
            if (!this.inputs.number) {
                this.errors.number = 'Fill in the number';
                valid = false;
            }
            if (!this.inputs.city) {
                this.errors.city = 'Fill in the city name';
                valid = false;
            }
            if (!this.inputs.state) {
                this.errors.state = 'Select the state';
                valid = false;
            }

            return valid;
        }
    },
    watch: {
        'inputs.fullname'(value) {
            if (this.submitted) return;

            const formatted = value.replace(/[0-9]/g, '');
            this.errors.fullname = formatted.length < 5 ? 'Fill in the full name' : '';
            this.$nextTick(() => {
                this.inputs.fullname = formatted;
            })
        },
        'inputs.zipCode'(value) {
            if (this.submitted) return;

            const formatted = value.replace(/\D/g, '');
            this.errors.zipCode = formatted.length !== 5 ? 'Fill in a valid zip code' : '';
            this.$nextTick(() => {
                this.inputs.zipCode = formatted;
            })
        },
        'inputs.street'(value) {
            if (this.submitted) return;

            this.errors.street = value.trim() === '' ? 'Fill in the street' : '';
        },
        'inputs.number'(value) {
            if (this.submitted) return;

            this.errors.number = value.trim() === '' ? 'Fill in the number' : '';
        },
        'inputs.city'(value) {
            if (this.submitted) return;

            this.errors.city = value.trim() === '' ? 'Fill in the city name' : '';
        },
        'inputs.state'() {
            this.errors.state = '';
        }
    }
}
</script>