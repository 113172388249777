<template>
  <div class="tab-content pickup-in-store">
    <div class="text-area">
      <h2>Same-Day Delivery</h2>
      <p class="p-large first">Purchase your products online and get them delivered today (or schedule a two-hour
        delivery
        window).Order cutoff times will vary by postal code. Delivery starts at $6.95.
      </p>

      <div class="container-numbers-list">
        <div class="item-numbers-list">
          <div class="icon-numbers-list">
            <img src="@/assets/icons/bg-number.svg" alt="bg number">
            <span>1</span>
          </div>
          <p>Choose your product, select the "Same-Day Delivery" option, and input your zip code.</p>
        </div>

        <div class="item-numbers-list">
          <div class="icon-numbers-list">
            <img src="@/assets/icons/bg-number.svg" alt="bg number">
            <span>2</span>
          </div>
          <p>We'll send you a notification as soon as your order has been packed and is on its way to your requested
            address.</p>
        </div>

        <div class="item-numbers-list">
          <div class="icon-numbers-list">
            <img src="@/assets/icons/bg-number.svg" alt="bg number">
            <span>3</span>
          </div>
          <p>Once your order has been delivered, we'll send you a confirmation notification.</p>
        </div>
      </div>

      <h3>
        Love Same-Day Delivery? Level up and save on delivery fees with…
      </h3>
      <h4>
        Same-Day Unlimited
      </h4>
      <p class="p-body">
        The subscription that delivers your beauty in as little as two hours – every time you shop.There's no order
        minimum, and you can try it FREE§ for 30 days.
      </p>
      <a href="https://sephora.com" target="_blank">
        <Button>Learn more at Sephora.com</Button>
      </a>

      <a href="https://www.sephora.com/beauty/same-day-unlimited-terms-conditions" class="regular-link">
        See terms
      </a>
    </div>
    <div class="image-area">
      <img src="~@/assets/images/giftbox-sameday.png" alt="Purple Gold Giftbox" />
      <div class="shadow"></div>
    </div>
  </div>
</template>

<script>
import Button from 'components/Button/Button';

export default {
  name: 'SameDayDeliveryTab',
  components: {
    Button
  }
}
</script>