<template>
  <div class="tab-content pickup-in-store">
    <div class="text-area">
      <h2>Buy Online, Pick Up Curbside</h2>
      <p class="p-large first">Get your products at a store near you today. Curbside Pickup is available at most
        locations.</p>

      <div class="container-numbers-list">
        <div class="item-numbers-list">
          <div class="icon-numbers-list">
            <img src="@/assets/icons/bg-number.svg" alt="bg number">
            <span>1</span>
          </div>
          <p>Choose your product and select your store from the "Buy Online & Pick Up" option to see if Curbside Pickup
            is available.</p>
        </div>

        <div class="item-numbers-list">
          <div class="icon-numbers-list">
            <img src="@/assets/icons/bg-number.svg" alt="bg number">
            <span>2</span>
          </div>
          <p>We'll send you a notification to come pick up your order when it's ready.</p>
        </div>

        <div class="item-numbers-list">
          <div class="icon-numbers-list">
            <img src="@/assets/icons/bg-number.svg" alt="bg number">
            <span>3</span>
          </div>
          <p>Check your order pick up notification for instructions on your store's Curbside Pickup location, including
            Curbside hours. Upon arrival, use the "I'm Here for Curbside" button.</p>
        </div>
      </div>

      <h4>Need more details? Check our main website below.</h4>
      <a href="https://sephora.com" target="_blank">
        <Button>Go to Sephora.com</Button>
      </a>
    </div>
    <div class="image-area">
      <img src="~@/assets/images/giftbox-pickupcurbside.png" alt="Gold Ornament Ribbon" />
      <div class="shadow"></div>
    </div>
  </div>
</template>

<script>
import Button from 'components/Button/Button';

export default {
  name: 'PickupCurbsideTab',
  components: {
    Button
  }
}
</script>