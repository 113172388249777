import { createRouter, createWebHistory } from 'vue-router';
import Login from 'views/Login/Login';
import Intro from 'views/Intro/Intro';
import Experience from 'views/Experience/Experience';
import Home from 'views/Home/Home';
import Category from 'views/Category/Category';
import Expert from 'views/Expert/Expert';
import Collection from 'views/Collection/Collection';
import ProductDetail from '../views/ProductDetail/ProductDetail';
import Cart from "@/views/Cart/Cart";
import Faq from 'views/Faq/Faq';
import HowToShop from 'views/HowToShop/HowToShop';
import RePurposed from 'views/RePurposed/RePurposed';
import NotFound from 'views/NotFound/NotFound';

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Sephora',
        }
    },
    {
        path: '/intro',
        name: 'Intro',
        meta: { requiresAuth: true },
        component: Intro,
        props: true,
    },
    {
        path: '/experience',
        name: 'Experience',
        meta: { requiresAuth: true },
        component: Experience,
        props: true,
    },
    {
        path: '/home',
        name: 'Home',
        meta: { requiresAuth: true },
        component: Home,
        props: true,
    },
    {
        path: '/product/:id',
        name: 'Product',
        meta: { requiresAuth: true },
        component: ProductDetail,
        props: true,
    },
    {
        path: '/category/:id',
        name: 'Category',
        meta: { requiresAuth: true },
        component: Category,
        props: true,
    },
    {
        path: '/expert/:id',
        name: 'Expert',
        meta: { requiresAuth: true },
        component: Expert,
        props: true,
    },
    {
        path: '/collection/:id',
        name: 'Collection',
        meta: { requiresAuth: true },
        component: Collection,
        props: true,
    },
    {
        path: '/cart',
        name: 'Holiday Wishlist',
        meta: { requiresAuth: true },
        component: Cart,
        props: true,
    },
    {
        path: '/faq',
        name: 'F.A.Q',
        meta: { requiresAuth: true },
        component: Faq,
        props: true,
    },
    {
        path: '/how-to-shop',
        name: 'Holiday Savings Event',
        meta: { requiresAuth: true },
        component: HowToShop,
        props: true,
    },
    {
        path: '/re-purposed',
        name: 'RePurposed',
        meta: { requiresAuth: true },
        component: RePurposed,
        props: true
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        meta: { requiresAuth: true },
        component: NotFound,
        props: true
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

    scrollBehavior(to) {
        if (to.hash) {
            document.body.style.overflow = '';
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else {
            document.body.style.overflow = '';
            return {
                top: 0
            }
        }
    }
})

export default router
