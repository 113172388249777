<template>
    <div class="input"
        :class="[error && 'error', fullWidth && 'fullwidth', disabled && 'disabled', marginBottom && 'margin-bottom', fiveWidth && 'fiveWidth',]">
        <label :for="id">{{ label }}</label>

        <div v-if="select" class="select-container">
            <select v-model="value" :id="id" :name="name" :disabled="disabled" :autofocus="autoFocus"
                :placeholder="placeholder">
                <option value="" disabled selected>Select your state</option>
                <slot />
            </select>
        </div>

        <textarea v-else-if="multiline" v-model="value" :id="id" :name="name" :placeholder="placeholder"
            :maxlength="maxLength" :disabled="disabled" :autofocus="autoFocus" :rows="rows" />

        <input v-else-if="mask" v-model="value" :id="id" :type="type" :name="name" :placeholder="placeholder"
            :disabled="disabled" :autofocus="autoFocus" maxlength="14">

        <input v-else v-model="value" :id="id" :type="type" :name="name" :placeholder="placeholder"
            :maxlength="maxLength" :disabled="disabled" :autofocus="autoFocus">

        <span v-if="helperText">{{ helperText }}</span>
    </div>
</template>

<script>
export default {
    name: 'Input',
    props: {
        label: String,
        name: String,
        id: String,
        type: {
            type: String,
            default: 'text'
        },
        modelValue: [String, Number],
        placeholder: String,
        helperText: String,
        maxLength: Number,
        rows: Number,
        multiline: Boolean,
        fullWidth: Boolean,
        select: Boolean,
        disabled: Boolean,
        error: Boolean,
        autoFocus: Boolean,
        marginBottom: Boolean,
        fiveWidth: Boolean,
        mask: String
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                if (this.mask) {
                    const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                    value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                    this.$emit('update:modelValue', value)
                } else {
                    this.$emit('update:modelValue', value)
                }
            }
        }
    },
    methods: {
    }
}
</script>