<template>
  <div class="collection page">
    <Hero class="collection" :title="name" :description="description" :image="background" backButton/>
    <ProductList :products="products" compact/>
  </div>
</template>

<script>
import Hero from 'components/Hero/Hero';
import ProductList from 'components/ProductList/ProductList';
import collections from 'assets/data/collections.json';

export default {
  name: 'Collection',
  components: {
    Hero,
    ProductList
  },
  data() {
    return {
      name: '',
      description: '',
      products: null,
      background: '',
      currentRoute: this.$route.path
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        // A URL mudou, você pode executar a lógica que deseja aqui
        // Por exemplo, você pode recarregar a página usando window.location.reload()
        window.location.reload();
      }
    },
  },
  created() {
    const pathArr = this.$route.path.split('/');
    const data = collections[pathArr[pathArr.length - 1]];

    if (data) {
      this.name = data.name;
      this.description = data.description;
      this.products = data.products;
      this.background = data.background;
    }
  }
}
</script>
