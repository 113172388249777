<template>
  <div class="product-card">
    <div v-if="requested" class="bow-branded"></div>

    
    <div class="content-wrapper">
      <div class="image">
        <LazyImage :src="imageURL" :alt="product.title" />
      </div>
      <div class="text-content">
        <p class="brand">{{ product.brand }}</p>
        <h3>{{ product.title }}</h3>
      </div>

      <div class="price-content">
        <p class="price">{{ formatPrice }}</p>
        <div class="buttons">
          <router-link :to="{ name: 'Product', params: { id: product.productID } }">
            <Button @click="handleOpenDetails">Discover</Button>
          </router-link>
          <!-- <Button outlined @click="handleRequestSample" :disabled="requested || !product.sampleRequest">
        {{ requested ? 'Added to wishlist' : 'Request a sample' }}
      </Button> -->

          <Button :class="{
            requestedClass: requested,
            outline: !requested,
            disabledClass: requested || !product.sampleRequest
          }" @click="handleRequestSample" :disabled="requested || !product.sampleRequest">
            <img :src="requested || !product.sampleRequest
              ? require('@/assets/icons/whitebag.svg')
              : require('@/assets/icons/bag-black.svg')" alt="bag icon" />
          </Button>

        </div>
      </div>

    </div>
  </div>

  <div class="gift-image">
    <img src="~@/assets/images/dense-group-2.png" alt="Gift">
  </div>
</template>

<script>
import LazyImage from '../LazyImage/LazyImage';
import Button from '../Button/Button';
import { useGtag } from "vue-gtag-next";

export default {
  name: 'ProductCard',
  components: {
    LazyImage,
    Button
  },
  props: {
    compact: Boolean,
    product: {
      productID: Number,
      title: String,
      brand: String,
      price: String,
      sampleRequest: Boolean,
      heroImage: String
    }
  },
  data() {
    return {
      update: 0,
      imageURL: `${process.env.VUE_APP_BASE_URL}/getImage/${this.product.productID}/${this.product.heroImage}`,
    }
  },
  computed: {
    requested() {
      this.update;
      return localStorage.cart && JSON.parse(localStorage.cart).includes(this.product.productID);
    },
    formatPrice() {
      let str = '';
      if (this.validPrice(this.product.price)) str += `$${this.product.price}`;
      if (this.validPrice(this.product.value) && this.product.value.toUpperCase() !== "NO") str += ` (Value $${this.product.value})`;
      return str;
    }
  },
  emits: ['requestSample', 'openDetails'],
  methods: {
    validPrice(value) {
      return !value || String(value).toLowerCase().includes('n/a') ? false : true
    },
    handleOpenDetails() {
      this.$emit('openDetails', this.product.productID);
    },
    handleRequestSample() {
      const updatedCart = [...(localStorage.cart ? JSON.parse(localStorage.cart) : []), this.product.productID];
      localStorage.cart = JSON.stringify(updatedCart);
      this.update++;

      this.$emit('requestSample', this.product.productID);
      window.dispatchEvent(new CustomEvent('cart-changed'));
      this.trackRequest(this.product.productID);
    },
    trackRequest(id) {
      let user = JSON.parse(localStorage.user);

      const { set, event, config } = useGtag()
      config({
        'user_id': user.id + "",
        'dimension1': 'Visitor',
      });
      set({
        'dimension1': 'Visitor',
      });
      event('request_product', {
        'user_id': user.id + "",
        'event_category': 'request product',
        'event_label': 'Requesting product - ' + id
      })
    },
    checkScroll() {
      const giftImage = document.querySelector('.gift-image');
      const scrollY = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollY >= documentHeight) {
        giftImage.style.opacity = 1;
        giftImage.classList.add('bounce');
      } else {
        giftImage.style.opacity = 0;
        giftImage.classList.remove('bounce');
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.checkScroll);
    this.checkScroll();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScroll);
  }
}
</script>
