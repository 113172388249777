<template>
    <div class="no-products">
        <div>
            <h2>My products</h2>
            <p class="small">0 products selected</p>
            <p>Oops, looks like you haven't selected any product yet.</p>
            <p class="strong">Wanna keep browsing?</p>
            <Button to="/home">Discover our products</Button>
        </div>
    </div>
</template>

<script>
import Button from 'components/Button/Button';

export default {
    name: 'ProductList',
    components: {
        Button
    },
    props: {
        products: Array
    },
    
}
</script>