<template>
  <div class="how-to-shop page">
    <Hero backButton title="Sephora Savings Event & Convenience Offerings" image="HowToShop.webp" />
    <div class="how-to-shop__content">
      <div class="banner">
        <img src="~@/assets/images/Box.png" alt="Holiday saving events" />
      </div>
      <div class="tab-background">
        <TabBar @change="handleChangeTab" :tabs="tabs" :selected="tab" />
        <SameDayDeliveryTab v-if="tab === 0" />
        <PickupStoreTab v-else-if="tab === 1" />
        <PickupCurbsideTab v-else-if="tab === 2" />
        <FreeShippingTab v-else-if="tab === 3" />
      </div>
    </div>
    <div class="hear-from-you">
      <div class="wrapper">
        <h2>We Want To Hear From You!</h2>
        <div v-if="!completed">
          <p class="question">Have you tried Sephora's Same-Day Delivery service?</p>
          <select name="service" id="service" v-model="service">
            <option value="">Select your answer</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>

          <p class="question">How would you rate your experience from 1-5?</p>
          <div class="stars">
            <div @click="handleClick(1)" :class="stars > 0 ? 'selected' : ''" class="star"></div>
            <div @click="handleClick(2)" :class="stars > 1 ? 'selected' : ''" class="star"></div>
            <div @click="handleClick(3)" :class="stars > 2 ? 'selected' : ''" class="star"></div>
            <div @click="handleClick(4)" :class="stars > 3 ? 'selected' : ''" class="star"></div>
            <div @click="handleClick(5)" :class="stars > 4 ? 'selected' : ''" class="star"></div>
          </div>

          <Button @click="handleSubmit()">Send answers</Button>
          <p v-if="error" class="generic-error">{{ error }}</p>
        </div>
        <div v-else>
          <p class="completed">Thank you for completing our survey. Your input is greatly appreciated and will help us
            improve our services.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hero from 'components/Hero/Hero';
import TabBar from './components/TabBar';
import PickupStoreTab from './components/PickupStoreTab';
import PickupCurbsideTab from './components/PickupCurbsideTab';
import SameDayDeliveryTab from './components/SameDayDeliveryTab';
import FreeShippingTab from './components/FreeShippingTab';
import Button from 'components/Button/Button';
import axios from "axios";
import { useGtag } from "vue-gtag-next";

export default {
  name: 'HowToShop',
  components: {
    Hero,
    TabBar,
    PickupStoreTab,
    PickupCurbsideTab,
    SameDayDeliveryTab,
    FreeShippingTab,
    Button
  },
  data() {
    return {
      tabs: ['Same-day delivery', 'Pick up in store', 'Pick up curbside', 'Free shipping'],
      tab: 0,
      stars: 0,
      finalStars: 0,
      service: '',
      completed: false,
      error: '',
      loading: ''
    }
  },
  methods: {
    handleChangeTab(val) {
      this.tab = val;
    },
    handleHover(value) {
      this.stars = value
    },
    handleClick(value) {
      this.stars = value;
    },
    handleSubmit() {

      if (this.stars === 0 || this.service === "") {
        this.error = "Fill a valid review."
        return false;
      }

      const url = process.env.VUE_APP_BASE_URL + "/review";
      const data = {
        service: this.service,
        review: this.stars,
      };

      const config = {
        headers: {
          'authorization': "Bearer " + localStorage.token
        }
      };

      this.loading = true;

      axios.post(url, data, config)
        .then(data => {
          if (data.status !== 200) {
            this.error = `Something went wrong: ${data.status}`
            this.loading = false;
            return;
          }

          this.completed = true;
        })
        .catch(err => {
          this.error = `Something went wrong: ${err}`
          this.loading = false;
          console.log(err);
        });
    },
  }
}
</script>