<template>
    <div class="not-found">
        <div class="image-absolute-left"></div>
        <div class="section-hero">
            <div class="banner">
                <h1 class="white">
                    The Holiday Isn’t Over!
                </h1>
                <p class="p-large white">
                    This page doesn’t exist or was removed. Please, go back to the homepage.
                </p>
                <router-link to="/home">
                    <button>Go Back Shopping</button>
                </router-link>
            </div>

            <div class="content-gift">
                <div class="gift-image-animation"></div>
                <div class="shadow-image"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>