<template>
  <div class="faq page">
    <Hero title="FAQ" backButton image="F.A.Q.webp" />
    <div class="faq__content wrapper">
      <div v-for="(item, index) in faqs" :key="index" :class="{ active: activeIndex === index }">
        <div class="faq-title" @click="toggle(index)">
          <h3>{{ item.question }}</h3>
          <span class="icon-arrow"></span>
        </div>
        <div class="faq-content" :class="{ 'is-expanded': activeIndex === index }" v-html="item.answer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Hero from 'components/Hero/Hero';

export default {
  name: 'Collection',
  components: {
    Hero
  },
  data() {
    return {
      activeIndex: null,
      faqs: [
        {
          question: "Can I share this link with other members of my team?",
          answer: `We are thrilled to welcome you to Sephora’s Holiday Press Hub and selected you to be among the first to
            preview into this year’s product assortment for a reason! That said, if you’d like to request access for
            another member of your team or transfer your login to a colleague, please reach out to Mason Sheridan
            (msheridan@devriesglobal.com) for assistance.`
        },
        {
          question: "What will I view on the site?",
          answer: `Please use this site as a resource to acquaint yourself with a curated selection of Sephora’s most
            anticipated holiday offerings. From perusing product details, downloading high-res assets, directly requesting
            samples, and learning more on the season’s top picks from our Beauty Directors, there is plenty to explore to
            inspire your holiday coverage.`
        },
        {
          question: "By when should I submit any product requests?",
          answer: `You are permitted to submit product requests for the duration of the holiday season, pending inventory. If
            press samples are no longer available of a given product, the add-to-cart function will be disabled on the
            product page.`
        },
        {
          question: "When can I anticipate receiving my product samples?",
          answer: `We kindly ask that you allow 7-10 days to receive your product as the team will be hand-packing your
          selections just for you! Should you need a sample for a time-sensitive story, we have given you the option to
          let us know on the wishlist page will get the product to you as quickly as we can.`
        },
        {
          question: "Does Sephora Collection have more holiday products than those included on this site?",
          answer: `Yes! To view all Sephora Collection holiday products, please visit
          <a href="https://www.sephora.com/brand/sephora-collection" target="_blank">sephora.com/brand/sephora-collection</a>.`
        },
        {
          question: "Will Sephora be selling additional holiday exclusives beyond what is included on this site?",
          answer: `Many! The Holiday Press Hub features a curated sampling of some of our top selections based on our
          most-anticipated launches, but you can view the complete holiday assortment on <a
          href="https://www.sephora.com"
          target="_blank">Sephora.com</a>.`
        },
        {
          question: "Are all these featured products available to shop now?",
          answer: `Please refer to individual product pages to confirm their availability on the Sephora website! If noted as
          under embargo, the product is only available here for the time being as a special sneak peek just for you.`
        },
        {
          question: "What if I have a question for one of the brands, or would like to request an interview or quote?",
          answer: `For any brand-specific questions you may have, please reach out to Mason Sheridan
          (msheridan@devriesglobal.com),
          who will connect you with the appropriate press contact.`
        },
        {
          question: "Question. Lorem ipsum dolor sit amet?",
          answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
          Phasellus molestie enim ut lacus ultricies, porttitor euismod ex tincidunt. 
          Proin pretium vestibulum feugiat. Proin interdum quis odio sed pellentesque. 
          Aliquam vel commodo leo. Curabitur ac velit non lectus maximus cursus vitae sed risus.`
        },
      ]
    };
  },
  methods: {
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    }
  }
};
</script>