<template>
    <div class="dropdown">
        <button :class="subMenuOpen ? 'active' : null" @click="toggle" class="nav-toggle">
            {{ settings.title }}
        </button>
        <div v-if="subMenuOpen" class="submenu">
            <router-link v-for="(links, index) in settings.node" :key="`${ settings.type }-${ index }`"
                class="subnav-link" :to="{ name: settings.type, params: { id: links.id }}"
            >
                {{ links.name }}
            </router-link>
        </div>
    </div>
</template>
<script>
import emitter from 'tiny-emitter/instance';

export default {
    name: 'Dropdown',
    props: {
        settings: Object
    },
    data() {
        return {
            subMenuOpen: false
        };
    },
    mounted() {
        emitter.on('closeAll', (type) => {
            if (type !== this.settings.type) {
                this.subMenuOpen = false;
            }
        });
    },
    methods: {
        toggle() {
            this.subMenuOpen = !this.subMenuOpen;
            emitter.emit('closeAll', this.settings.type);
        },
    }
}
</script>
