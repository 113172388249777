<template>
  <div class="menu" :class="visible && 'active'">
    <div class="sidebar">
      <div class="top">
        <router-link class="nav-link" to="/home">Home</router-link>
        <router-link class="nav-link" to="/intro">Welcome Page</router-link>
        <router-link class="nav-link" to="/experience">Don’t overthink it!</router-link>
        <Dropdown :settings="collections"/>
        <Dropdown :settings="categories"/>
        <Dropdown :settings="experts"/>
        <router-link class="nav-link" to="/faq">F.A.Q</router-link>
        <router-link class="nav-link" to="/how-to-shop">How To Shop</router-link>
        <router-link class="nav-link" to="/re-purposed">Beauty (Re)Purposed</router-link>
      </div>
    </div>
    <div class="bottom">
      <div class="letter"></div>
      <!-- <p>Reach out to <a href="mailto:msheridan@devriesglobal.com">msheridan@devriesglobal.com</a> with any questions or concerns! -->
      <p>Need any help or want to contact us, write to us at xxxxx@xxxx.com
      </p>
    </div>
  </div>
</template>

<script>
import Dropdown from "components/Header/Dropdown";
import data from "assets/data";

export default {
  name: 'Menu',
  components: {
    Dropdown
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      closeAll: false,
      collections: {
        title: "Explore Product",
        node: data.collections,
        type: "Collection"
      },
      categories: {
        title: "Explore By Category",
        node: data.categories,
        type: "Category"
      },
      experts: {
        title: "Expert Picks",
        node: data.experts,
        type: "Expert"
      },
    };
  }
}
</script>