<template>
    <div class="login page">
        <div class="white-box">
            <h1 class="small">Welcome</h1>
            <div class="inputs-container">
                <Input label="E-mail" name="email" id="email" type="email" placeholder="Write your e-mail" :helperText="emailError"
                    v-model="email" fullWidth  :error="Boolean(emailError)" :maxLength="250"
                />
                <Input label="Password" name="password" id="password" type="password" placeholder="Input your password" :helperText="passwordError"
                    v-model="password" fullWidth :error="Boolean(passwordError)" :maxLength="250"
                />
                <p class="error">{{ error }}</p>
                <Button @click="handleSubmit">Enter</Button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';

export default {
    name: 'Login',
    components: {
        Button,
        Input
    },
    data() {
        return {
            email: "",
            password: "",
            emailError: "",
            passwordError: "",
            error: ""
        }
    },
    created() {
        localStorage.login = JSON.stringify(false);
    },
    methods: {
        login() {
            localStorage.removeItem('cart');
            const body = {
                password: this.password,
                email: this.email
            };

            axios.post(process.env.VUE_APP_BASE_URL + "/login", body)
            .then(res => {
                if (!res.data.success) {
                    this.handleError(res.data.message);
                    return;
                }

                localStorage.login = JSON.stringify(true);
                localStorage.user = JSON.stringify(res.data.user);
                localStorage.token = res.data.token;
                this.$router.push('/intro');
            })
            .catch(err => {
                this.handleError(err.data.message);
            });
        },
        handleError() {
            /*if (message === 'Incorrect password') {
                this.passwordError = message;
            } else {
                this.emailError = message;
            }*/
            this.error = 'We hope you had a wonderful holiday season! For any additional inquiries, please reach out msheridan@devriesglobal.com.';
        },
        handleSubmit() {
            let valid = true;

            if (!this.email) {
                this.emailError = 'Fill in the email';
                valid = false;
            }

            if (!this.password) {
                this.passwordError = 'Fill in the password';
                valid = false;
            }

            if (valid) {
                this.login();
            }
        }
    },
    watch: {
        email(value) {
            this.emailError = value.length < 5 ? 'Fill in the email' : '';
        },
        password(value) {
            this.passwordError = !value.length ? 'Fill in the password' : '';
        },
    }
}
</script>
