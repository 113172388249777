import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag, {useGtag} from "vue-gtag-next";
const app = createApp(App);
import axios from 'axios';


app.use(VueGtag, {
    property: {
        id: "G-5ZYJJK8NQN"
    }
});


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (localStorage.getItem('token') == null) {
            next({
                path: '/',
                params: { nextUrl: to.fullPath }
            })
        } else {
            axios.get(
                process.env.VUE_APP_BASE_URL+'/me',
                { headers: { 'authorization': "Bearer "+localStorage.token }}
            )
            .then(() => {
                let user = JSON.parse(localStorage.user);

                const { set, event, config } = useGtag()
                config({
                    'user_id': user.id + "",
                    'dimension1': 'Visitor',
                });
                set({
                    'dimension1': 'Visitor',
                });
                event('page_view', {
                    'user_id': user.id + "",
                    'event_category' : 'page_view',
                    'event_label' : to.fullPath
                })
                next();
            })
            .catch(() => {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                next({
                    path: '/',
                    params: { nextUrl: to.fullPath }
                })
            })
        }
    } else {
        next() // make sure to always call next()!
    }
})
app.use(router).mount('#app')
