<template>
    <div class="expert-card">
        <router-link :to="{ name: 'Expert', params: { id: data.id } }">
            <div class="content">
                <img :src="'https://sephora-media-hub.s3.us-east-2.amazonaws.com/assets/images/experts/' + data.image"
                    :alt="data.name" />
                <div class="info">
                    <p class="title">Expert</p>
                    <p class="name">{{ data.name }}</p>
                    <p class="description">{{ data.description }}</p>
                </div>
                <div class="buttons">
                    <Button to='/home'>See products</Button>
                    <InstagramLink :url="data.instagram" />
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import InstagramLink from 'components/InstagramLink/InstagramLink';
import Button from 'components/Button/Button';

export default {
    name: 'ExpertCard',
    components: {
        InstagramLink,
        Button
    },
    props: {
        data: Object
    }
}
</script>
